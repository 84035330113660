import {useEffect, useState} from "react";
import Loader from "../common/elements/loader";
import {Chart} from "react-google-charts";
import {getChartOptions, chartContainerStyles} from './chartCommon';
import _ from "lodash";

const AllBioMarkersChart = (props) => {
    const {dashboardData} = props;
    const [chartData, setChartData] = useState([]);
    const calculateChart = () => {
        const headears = ["Items", "Hazard", {'type': 'string', 'role': 'style'}];
        const sortedRisks = _.sortBy(dashboardData,['hazardRate'] );
        const localChartData = [];
        let counter=1;
        localChartData.push(headears);
        sortedRisks.map((data, idx)=>{
            if(data.hazardRate >= 1) {
                localChartData.push([data.biomarker, data.hazardRate,'point { size: 2; shape-type: circle;  }']);
            }
        })
        console.log(dashboardData)
        console.log(chartData)
        setChartData(localChartData);
    }
    useEffect(() => {
        calculateChart();
    },[props.dashboardData]);
    const chartOptions = getChartOptions("All Biomarkers","Biomarkers","Hazard");
    chartOptions.hAxis.textStyle.fontSize=7;
    return (
        <div className={chartContainerStyles}>
            <Chart
                chartType="ScatterChart"
                data={chartData}
                options={chartOptions}
                loader={<div>Drawing Chart</div>}
            />
        </div>
    )
}

export default AllBioMarkersChart;