
import './input.css';
import PropTypes from "prop-types";
import {buttonCommon, blueButton, outlineButton} from "./classNames"

export const InputText = (props) => {
    const {id,value,name,className, type, 
        onChange, placeholder,error,info, onBlur, onFocus, label, autoFocus} = {type:'text',autoFocus:false,...props};
    let stateClassName='';
    let msg;

    if(info) {
        stateClassName='info';
        msg=info;
    }

    if(error) {
        stateClassName='error';
        msg=error;
    } 
    const inputClasses = `w-full rounded border-0 px-1 pt-1.5 pb-2 leading-3`;
    return(
        <div className={`inputWrapper border border-uhblue ${stateClassName} ${className}`}>
            {
                label && <label className='bg-uhblue-dark text-white'>{label}</label>
            }
            <input 
                type={type} 
                id={id}
                value={value} 
                name={name}
                onChange={e=>onChange(e.target.value)}
                placeholder={placeholder}
                onBlur={onBlur}
                onFocus={onFocus}
                className={inputClasses}
                autoFocus={autoFocus}
            />
            <div className="msg">
                {msg}
            </div>
        </div>
    );
}

export const InputReset = (props) => {
    const {value, onClick, className, outline} = {outline:true, ...props};
    let appliedClass = 'blueButton';
    if(outline) {
        appliedClass = `outlineBlueButton`
    }
    return (
        <div  className={`inline-block ${className}`}>
            <input
                type={'Reset'}
                value={value}
                onClick={onClick}
                onChange={()=>{}}
                className={`${buttonCommon} ${outlineButton}  ${appliedClass}`}
            />
        </div>

    );
}

export const InputSubmit = (props) => {
    const {text, onClick, className, outline, loading, disabled} = {outline:false, ...props};
    let appliedClass = 'blueButton';
    if(outline) {
        appliedClass = `outlineBlueButton`
    }
    let loadingClass,disabledClass ="";
    if(loading) {
        loadingClass = 'loading ';
    }
    if(disabled) {
        disabledClass = 'disabled'
    }

    const handleChange = (e) => {
        if(disabled || loading) {
            return false;
        }
        onClick(e);
    }
    return (
        <div className={`inline-block ${className} blueButtonContainer`}>
            <input
                type={'submit'}
                value={text}
                onClick={handleChange}
                onChange={()=>{}}
                className={`${buttonCommon} ${blueButton} ${appliedClass} ${loadingClass} ${disabledClass}`}
            />
        </div>

    );
}

InputText.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired
}

InputText.InputReset = {
    value: PropTypes.any.isRequired,
}

InputText.InputSubmit = {
    value: PropTypes.any.isRequired,
}