import Button from "../common/elements/button";
import {InputText} from "../common/elements/input";
import {useEffect, useState} from "react";
import httpClient from "../../httpClient";
import Loader from "../common/elements/loader";
import Select from "../common/elements/select";
import Modal from "view/common/elements/modal"
import {CSVLink} from 'react-csv';
import UploadFile from "./uploadFile";
import Link from "../common/elements/link";
import {buttonCommon, outlineButton} from "../common/elements/classNames"


const SavePortfolioPanel = (props) => {
    const ACTION_RENAME = "RENAME";
    const ACTION_COMPLETED = "";
    const DEFAULT_DOWNLOAD_NAME = "myReport"

    const [newPortfolioName, setNewPortfolioName] = useState('');
    const [savedPortfolios,setSavedPortfolios] = useState([]);
    const [savedPortfoliosLoading, setSavedPortfoliosLoading] = useState(false);
    const [portfolioSaving, setPortfolioSaving] = useState(false);
    const [selectedPortFolio, setSelectedPortfolio] = useState(0);
    const [selectedPortFolioName, setSelectedPortfolioName] = useState(DEFAULT_DOWNLOAD_NAME);
    const [showNameModal, setShowNameModal] = useState(false);
    const [savePortfolioError, setSavePortfolioError] = useState("");
    const [currentAction, setCurrentAction] = useState(ACTION_COMPLETED);
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);

    const saveExistingPortfolio = async () => {
        const response = await httpClient.put(`/risk/v1/portfolio/${selectedPortFolio}`,{
            portfolio:props.dashboardData
        })

        return response;
    }

    const renameExistingPortfolio = async () => {
        // renamePortfolio

        const response = await httpClient.post(`/risk/v1/renamePortfolio`,{
            portfolioId:selectedPortFolio,
            name: newPortfolioName
        })
        if(response.error) {
            setSavePortfolioError(response.error.response.data)
        } else {
            setShowNameModal(false);
            const newPortfolios = [...savedPortfolios];
            for(let i=0;i<newPortfolios.length;i++) {
                if(newPortfolios[i].id === selectedPortFolio) {
                    newPortfolios[i].name = newPortfolioName;
                }
            }
            setSavedPortfolios(newPortfolios);
            setSelectedPortfolio(0);
            setSelectedPortfolioName(DEFAULT_DOWNLOAD_NAME);
        }
        return response;
    }

    const savePortfolio = async () => {
        if(currentAction === ACTION_RENAME && showNameModal) {
            setPortfolioSaving(true);
            await renameExistingPortfolio();
            setPortfolioSaving(false);
            setCurrentAction(ACTION_COMPLETED);
            return;
        }
        if(selectedPortFolio === 0) { //New Portfolio,

            if(showNameModal) {  //Name modal is opened
                setPortfolioSaving(true);
                await createNewPortfolio();
                setPortfolioSaving(false);

            } else {
                setShowNameModal(true);
            }
        } else {
            setPortfolioSaving(true);
            await saveExistingPortfolio();
            setPortfolioSaving(false);
            //Existing portfolio
        }
    }

    const createNewPortfolio = async () => {
        const response = await httpClient.post(`/risk/v1/portfolio`,{
            portfolio:props.dashboardData,
            name: newPortfolioName
        });

        if(response.error) {
            setSavePortfolioError(response.error.response.data)
        } else {
            const newPortfolio = {
                id:response.portfolioId,
                name:response.name,
            }

            setSavedPortfolios([...savedPortfolios, newPortfolio]);
            setSelectedPortfolio(response.portfolioId);
            setSelectedPortfolioName(response.name);
            setShowNameModal(false);
        }
        setNewPortfolioName("");
        return response;
    }

    const getSavedPortfolios = async () => {
        setSavedPortfoliosLoading(true);
        // setSavedPortfoliosLoaded(false);
        const portfolios = await httpClient.get('/risk/v1/getSavedPortfolios');

        setSavedPortfoliosLoading(false);
        setSavedPortfolios(portfolios);
        // setSavedPortfoliosLoaded(true);
    }

    //Take incoming values and hazard rates from the backend and format to fill the dashboard
    const getNewDashboardData = (newData) => {
        if(!newData || !Array.isArray(newData)) {
            return [...props.dashboardData];
        }
        const portfolioValueMap={};
        newData.map((item)=>{
            portfolioValueMap[getKey(item.biomarkerId, item.unitId)] = item;
        })
        let hazardRate=0;
        let value="";
        let outOfBounds = false;
        let minValue=0;
        let maxValue=0;

        const newDashboardData = [];
        props.dashboardData.map((data)=>{
            if(portfolioValueMap[getKey(data.biomarkerId, data.unitId)]) {
                hazardRate = portfolioValueMap[getKey(data.biomarkerId, data.unitId)].hazardRate;
                value = portfolioValueMap[getKey(data.biomarkerId, data.unitId)].value+"";
                
                if(portfolioValueMap[getKey(data.biomarkerId, data.unitId)].outOfBounds) {
                    outOfBounds = portfolioValueMap[getKey(data.biomarkerId, data.unitId)].outOfBounds;
                    minValue = portfolioValueMap[getKey(data.biomarkerId, data.unitId)].minValue;
                    maxValue = portfolioValueMap[getKey(data.biomarkerId, data.unitId)].maxValue;
                } else {
                    minValue = 0;
                    maxValue=0;
                    outOfBounds=false;
                }

            } else {
                hazardRate = 0;
                value = "";
                outOfBounds=false;
                minValue=0;
                maxValue=0;
            }
            // newData = ;

            newDashboardData.push({...data, hazardRate, value, outOfBounds, minValue, maxValue})
        });

        return newDashboardData;
    }

    const changePortfolio = async (portfolio) => {

        let newDashboardData = [];

        if(portfolio) {
            setSelectedPortfolio(portfolio.value);
            setSelectedPortfolioName(portfolio.label);
            const details = await httpClient.get(`/risk/v1/portfolio/${portfolio.value}`)
            newDashboardData = getNewDashboardData(details.values);
            props.populatePortfolioData(newDashboardData);
        } else {
            setSelectedPortfolio(0);
            setSelectedPortfolioName(DEFAULT_DOWNLOAD_NAME);
            props.populatePortfolioData(getNewDashboardData([]));
        }


    }

    const getKey = (biomarkerId, unitId) => {
        return `${biomarkerId}-${unitId}`;
    }
    const clearMessage = () => {
        setSavePortfolioError("");
    }
    const buttonClassName = `inline-block`;

    useEffect( ()=>{
         getSavedPortfolios();
    },[])

    const renamePortfolio = () => {
        setCurrentAction(ACTION_RENAME);
        let existingName='';
        savedPortfolios.map((portfolio)=>{
            if(portfolio.id === selectedPortFolio) {
                existingName = portfolio.name;
            }
        })
        setNewPortfolioName(existingName);
        setShowNameModal(true);
    }

    const modalClose = () => {
        setShowNameModal(false);
    }

    const csvHeaders = [
        { label: "Biomarker", key: "biomarker" },
        { label: "Value", key: "value" },
        { label: "Unit", key: "unit" }
    ];

    const onFileUploadClose = () => {
        setShowFileUploadModal(false);
    }

    const fileUploadCloseAsync = async() => {
        setTimeout(() => {
            setShowFileUploadModal(false);
        }, 3000)
    }

    const onFileUploadSuccess = async (key) => {
        fileUploadCloseAsync();
        const dashboardData = await httpClient.get(`/risk/v1/getUploadedFileData?key=${key}`);
        const newData = getNewDashboardData(dashboardData.values)
        props.populatePortfolioData(newData);
    }

    const linkButtonClassNames = `${buttonCommon} ${outlineButton}`;
    return (
        <div className={`w-full pb-3 border-b inline-block md:flex align-middle justify-between border-uhblue-light`}>
            <div className={`w-full md:w-1/3 lg:w-1/3 inline-block`}>
                {
                    savedPortfoliosLoading && <Loader />
                }
                {
                    !savedPortfoliosLoading &&
                    <Select
                        value={selectedPortFolio}
                        options={savedPortfolios}
                        onChange={changePortfolio}
                        valueKey={`id`}
                        labelKey={`name`}
                        placeholder={'Select a saved portfolio'}
                    />
                }


            </div>
            <div className={`mt-2 md:mt-0 w-full md:w-2/3 lg:w-2/3 inline-block `}>
                <Button
                    text={`Save`}
                    onClick={savePortfolio}
                    loading={portfolioSaving||savedPortfoliosLoading}
                    className={`${buttonClassName} ml-0 md:ml-2`}
                />

                {/*<Button*/}
                {/*    text={`Save As`}*/}
                {/*    onClick={savePortfolio}*/}
                {/*    loading={savedPortfoliosLoading||portfolioSaving}*/}
                {/*    disabled={selectedPortFolio ===0}*/}
                {/*    className={buttonClassName}*/}
                {/*/>*/}

                <Button
                    text={`Rename`}
                    onClick={renamePortfolio}
                    loading={savedPortfoliosLoading||portfolioSaving}
                    disabled={selectedPortFolio ===0}
                    className={`${buttonClassName} ml-2`}
                />

                {/*<Button*/}
                {/*    text={`Delete`}*/}
                {/*    onClick={savePortfolio}*/}
                {/*    loading={savedPortfoliosLoading||portfolioSaving}*/}
                {/*    className={`${buttonClassName} outline`}*/}
                {/*    disabled={selectedPortFolio ===0}*/}
                {/*/>*/}


                <CSVLink
                    data={props.dashboardData}
                    headers={csvHeaders}
                    filename={`${selectedPortFolioName}.csv`}
                    className={`${linkButtonClassNames} ml-2`}
                >
                    Download
                </CSVLink>
                <Link
                    onClick={()=>{setShowFileUploadModal(true)}}
                    text={`Upload`}
                    className={`${linkButtonClassNames} ml-2`}
                />
            </div>

            <Modal open={showNameModal} onClose={modalClose}>
                <div className={`p-5 pb-0`}>
                    <div>
                        Enter the name of the portfolio
                    </div>
                    <InputText
                        value={newPortfolioName}
                        onChange={setNewPortfolioName}
                        error={savePortfolioError}
                        onFocus={clearMessage}
                    />
                    <Button
                        text={`Save Portfolio`}
                        onClick={savePortfolio}
                        loading={savedPortfoliosLoading||portfolioSaving}
                        className={`inline-block w-full mt-4`}
                    />
                </div>
            </Modal>

            <Modal open={showFileUploadModal} onClose={onFileUploadClose}>
                <UploadFile
                    onFileUploadSuccess={onFileUploadSuccess}
                    onFileUploadError={()=>{}}
                />
            </Modal>

        </div>
    )
}

export default SavePortfolioPanel;