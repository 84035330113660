import {useState} from 'react';
import Select from 'view/common/elements/select'
import {InputText} from 'view/common/elements/input'
import { TrashIcon } from '@heroicons/react/solid'

const Connection = (props) => {
    
    const {id, connectionType, allTypes, onDelete, onChange} = props;
    const [selectedType, setSelectedType] = useState({
                    value:connectionType.id,
                    label:connectionType.name
                });

    const [from, setFrom] = useState(props.from);
    const [to, setTo] = useState(props.to);
    const selectValues=[];

    allTypes.map(type => {
        selectValues.push({
            value:type.id, 
            label:type.name
        });
    });


    const changeSelectedType = (newConnection) => {
        setSelectedType(newConnection);
        onChange({
            id:id,
            from:from,
            to:to,
            connectionType:{id:newConnection.value, name:newConnection.label}
        })
    }

    const changeFrom = (value) =>{
        const newFrom = {...from};
        newFrom.name = value;
        setFrom(newFrom);
        onChange({
            id:id,
            from:newFrom,
            to:to,
            connectionType:connectionType
        })
    }

    const changeTo = (value) =>{
        const newTo = {...to};
        newTo.name = value;
        setTo(newTo);
        onChange({
            id:id,
            from:from,
            to:newTo,
            connectionType:connectionType
        })
    }

    const deleteConnection = () => {
        onDelete(id);
    }

    return (
        <div className='md:flex md:items-center mb-6 mt-10 md:mb-2 md:mt-5 border-b border-uhblue border-dashed'>

            <div className='w-full md:w-1/3 inline-block md:px-1'>
                <InputText
                    label='Start'
                    value={from.name}
                    onChange={changeFrom}
                />
            </div>
            <div className='w-full md:w-1/4 inline-block md:px-1 mb-4'>
                <Select
                    options={selectValues}
                    value={selectedType}
                    onChange = {changeSelectedType}
                />
            </div>
            <div className='w-full md:w-1/3 inline-block md:px-1'>
                <InputText
                    label='End'
                    value={to.name}
                    onChange={changeTo}
                />
            </div>
            <div className='w-full md:w-1/12 inline-block md:px-1'>
                <TrashIcon 
                    className='h-5 md:h-7 w-5 md:w-7  text-uhblue-darkest inline-block cursor-pointer mx-auto table'
                    onClick={deleteConnection}
                />
            </div>
        </div>
    )
}

export default Connection;