import {useEffect, useState} from "react";
import httpClient from "../../httpClient";

import InputPanel from "./inputPanel";
import OutputPanel from "./outputPanel";

import _ from "lodash";


import Loader from "../common/elements/loader";
import SensitivityPanel from "./sensitivityPanel";
import SavePortfolioPanel from "./savePortfolioPanel";

const RiskDashboard = () => {
    useEffect(() => {
        document.title = 'Risk Dashboard';
        getData();
    },[]);


    const [firstValueReceived, setFirstValueReceived] = useState(false);
    const [dashboardData, setDashboardData] = useState(null);
    const [gender, setGender] = useState("");
    const [age, setAge] = useState('');
    const [correlation, setCorrelation] = useState(35);


    const [topTenRisks, setTopTenRisks] = useState([]);

    const updateValue = (hazardData) => {

        if(hazardData.hazardRate) {
            hazardData.hazardRate = parseFloat(hazardData.hazardRate);
        } else {
            hazardData.hazardRate=0;
        }

        const newDashboardData = [...dashboardData];

        for( let i=0;i<newDashboardData.length;i++) {
            if(newDashboardData[i].biomarkerId === hazardData.biomarkerId && newDashboardData[i].unitId===hazardData.unitId) {
                newDashboardData[i].value = hazardData.value;
                newDashboardData[i].hazardRate = hazardData.hazardRate;
                newDashboardData[i].outOfBounds = hazardData.outOfBounds;
                if(newDashboardData[i].outOfBounds) {
                    newDashboardData[i].minValue = hazardData.minValue;
                    newDashboardData[i].maxValue = hazardData.maxValue;
                }

            }
        }

        setDashboardData(newDashboardData);
        setOutputData(newDashboardData);

    }

    const setOutputData = dashboardData => {
        if(!dashboardData || !Array.isArray(dashboardData)||dashboardData.length<1) {
            return;
        }
        let mH = 1;
        for( let i=0;i<dashboardData.length;i++) {
            if(dashboardData[i].hazardRate) {
                if(dashboardData[i].hazardRate > mH) {
                    mH = dashboardData[i].hazardRate;
                }
            }
        }
        const sortedRisks = _.reverse(_.sortBy(dashboardData,['hazardRate'] ));
        setTopTenRisks(_.slice(sortedRisks, 0,10));
        let num=0;
        let minBiomarkersRequired=1;

        for(let i=0;i<10;i++) {
            if(sortedRisks[i].hazardRate > 0) {
                num++;
            }
        }

        if(num >= minBiomarkersRequired) {
            setFirstValueReceived(true);
        } else  {
            setFirstValueReceived(false);
        }
    }

    const populatePortfolioData = (newDashboardData) => {
        setDashboardData(newDashboardData);
        setOutputData(newDashboardData);
    }

    const getData = async () => {
        let data =  await httpClient.get('/risk/v1/getDefaultDashboardData');
        // debugger;
        if(!data) {
            data={
                biomarkers:[]
            };
        }
        setDashboardData(data.biomarkers);
        setAge(parseInt(data.age));
        setGender(parseInt(data.gender));
    }

    const panelClassNames = 'w-full md:w-1/2 xl:w-1/3 pt-3 inline-block align-top h-96 md:h-screen';

    if(!dashboardData) {
        return (
            <Loader/>
        )
    }

    const correlationUpdated = (newCorrelation) => {
        setCorrelation(newCorrelation)
    }

    return (
        <div>
            {/*<div className={`h-96 md:h-screen`}>*/}
                <SavePortfolioPanel
                    dashboardData={dashboardData}
                    populatePortfolioData={populatePortfolioData}
                />
                <InputPanel
                    dashboardData={dashboardData}
                    className={`${panelClassNames}`}
                    onChange={updateValue}
                />
                <OutputPanel
                    age={age}
                    gender={gender}
                    className={`${panelClassNames}`}
                    topTenRisks={topTenRisks}
                    showPanel={firstValueReceived}
                    correlation={correlation}
                    setCorrelation={correlationUpdated}
                    setAge={setAge}
                    setGender={setGender}
                    dashboardData={dashboardData}
                />
            {/*</div>*/}

            <SensitivityPanel
                age={age}
                gender={gender}
                topTenRisks={topTenRisks}
                showPanel={firstValueReceived}
                correlation={correlation}
                className={`${panelClassNames}`}
            />

        </div>
    );
}

export default RiskDashboard;
