
import SensitivityChart from "./sensitivityChart";

const SensitivityPanel  = (props) => {
    const {topTenRisks, age, gender, correlation, showPanel, className} = props;
    const localClassNames = `w-full md:w-full xl:w-1/3 mt-20 xl:mt-0 inline-block xl:pl-2 \
     border-l-0 xl:border-l overflow-x-scroll border-t-2 md:border-t-0 border-uhblue-light`;
    if(!showPanel) {
        return (
            <div id="outputPanel" className={`${className} ${localClassNames}`}>
                <div className={`align-middle text-center pt-16 text-gray-800`}>
                    Please enter at least five biomarker values
                </div>
            </div>
        )
    }
    return (
        <div className={`${className} ${localClassNames}`}>
            <div className={`font-bold text-gray-800`}>
                Sensitivity Charts
            </div>
            {
                topTenRisks.map((risk,idx)=>{
                    if(risk.hazardRate >0 )
                        return (
                            <SensitivityChart
                                key={`OutputRow_${risk.biomarkerId}`}
                                topTenRisks={topTenRisks}
                                biomarker={risk.biomarker}
                                biomarkerId={risk.biomarkerId}
                                unit={risk.unit}
                                unitId={risk.unitId}
                                value={risk.value}
                                hazardRate={risk.hazardRate}
                                age={age}
                                gender={gender}
                                correlation={correlation}
                            />

                        )
                })
            }
            <SensitivityChart
                key={`OutputRow_Correlation`}
                topTenRisks={topTenRisks}
                biomarker={'Correlation'}
                age={age}
                gender={gender}
                correlation={correlation}
                unit={'%'}
                value={correlation}
            />
        </div>
    )
}

export default SensitivityPanel;