
import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import {getToken} from '../../../helpers/tokenStore'
import  Modal from '@mui/material/Modal';

import Login from 'view/public/auth/login'

import PropTypes from 'prop-types'

const PrivateRoute = (props) => {
    const location = useLocation();
    const token = getToken();
    const [modalStyle] = React.useState(getModalStyle);

    const handleClose = (...args) => {
        console.log(args);
    }

    function getModalStyle() {
        const top = 50 ;
        const left = 50;
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
            position:`absolute`
        };
    }


    const redirectPath = `/auth/login?redirect=${encodeURI(location.pathname)}`;
    if(!token || token === "undefined" || !props.loggedIn) {
        return <Redirect to={redirectPath} />;
    }

    /**
     * TODO
     * This Modal View is never triggered as props.loggedIn is already handled
     * in the if condition above. Another parameter needs to be introduced to handle
     * pages where you would want a modal instead of redirecting to the login page
     *  (In case there is a large form, and you would not want users to lose data if
     *  they get logged out in between filling it out)
     */
    return (
        <div>
            <div>
                <Modal
                    aria-labelledby="Login"
                    aria-describedby="Login Popup"
                    open={!props.loggedIn}
                    onClose={handleClose}
                >
                    <div style={modalStyle} className='block'>
                        <Login/>
                    </div>
                </Modal>
            </div>
            <Route {...props} />
        </div>
    );
};

PrivateRoute.propTypes = {
    loggedIn : PropTypes.bool.isRequired
}

export default PrivateRoute;