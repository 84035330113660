import {useEffect, useState} from 'react';
import SelectLib from 'react-select';
import PropTypes from "prop-types";

const Select = (props) => {
    const {
        onChange,
        className,
        isDisabled,
        isLoading,
        isClearable,
        isMulti,
        placeholder
    } = {isDisabled:false,isLoading:false,isClearable:true,isMulti:false, ...props};

    const [selectedValue, setSelectedValue] = useState(null);
    const [localOptions, setLocalOptions] = useState([]);

    const handleChange = (value) => {
        setSelectedValue(value)
        onChange(value)
    }

    const formatAndSetLocalOptions = (options, valueKey, labelKey ) => {
        const localOpts = [];
        options.map(item => {
            localOpts.push({
                value: item[valueKey],
                label: item[labelKey]
            })
        })

        setLocalOptions(localOpts);
    }

    useEffect(()=>{
        formatAndSetLocalOptions(props.options, props.valueKey, props.labelKey);
    },[props.options, props.valueKey, props.labelKey]);

    const setValue = (value) => {

        /* SEE https://stackoverflow.com/questions/8511281/check-if-a-value-is-an-object-in-javascript */
        if ( typeof value === 'object' && !Array.isArray(value) && value !== null ) {
            /* If type is object then directly set that as value*/
            setSelectedValue(value);
        } else {
            /* else look for the object with that value */
            localOptions.map((option)=>{
                if(option[props.valueKey] === value) {
                    setSelectedValue(option);
                }
            })
        }

    }
    useEffect(()=>{
        setValue(props.value);
    },[props.value])

    const customStyles = {
        control: base => ({
            ...base,
            height: 34,
            minHeight: 34,
            border:'1px solid #629DD1',
            outline:'none',
            "&:hover": {
                border:'1px solid #629DD1',
                boxShadow:'0 0 3px #629DD1'
            },
            "&:focus": {
                outline:'none',
            },
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            paddingTop: 2,
            paddingBottom: 2,
            color: '#629DD1',
        }),
        clearIndicator: (styles) => ({
            ...styles,
            paddingTop: 2,
            paddingBottom: 2,
            color: '#629DD1'
        }),
    };

    return (
        <div className={`selectContainer ${className}`}>
            <SelectLib
                value={selectedValue}
                onChange={handleChange}
                options={localOptions}
                isMulti={isMulti}
                isClearable={isClearable}
                isDisabled={isDisabled}
                isLoading={isLoading}
                placeholder={placeholder}
                className={``}
                styles={customStyles}
                theme={(theme) => ({
                    ...theme,

                    colors: {
                        ...theme.colors,
                        primary25: '#e8f2fa',
                        primary: '#629DD1',
                    },
                })}
            />
        </div>
    )
}

export default Select;

Select.propTypes = {
    options: PropTypes.array.isRequired, // array of objects that need to be displayed
    onChange: PropTypes.func.isRequired, // onChange handler
    valueKey: PropTypes.string.isRequired, // Key of objects (in options) to be used as the value.
                                                // can be same as labelKey
    labelKey: PropTypes.string.isRequired, // Key of objects (in options) to be used as the label.
                                                // can be same as valueKey
}