const  BaseAlert = () => {
    return (    
        <div className="notification text-white fixed top-2 right-2 bg-noti-success w-1/2 lg:w-1/5 rounded-lg hidden">
            <div className='allCenter transparent'>
                All is good
            </div>
        </div>
    )
}

export default BaseAlert;