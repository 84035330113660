
import httpClient from "../../httpClient";
import {useEffect, useState} from "react";
import _ from 'lodash';

const ACTUARIAL_HAZARD=4;
const ProjectedAgeRow = (props) => {
    let {title, hazardRate, className, age, gender} = props;
    let displayHazardRate;
    if(title === "Actuarial") {
        displayHazardRate=''
    } else {
        displayHazardRate = hazardRate;
    }
    // const [fivePercentileAge, setFivePercentileAge] = useState(null);
    // const [fiftyPercentileAge, setFiftyPercentileAge] = useState(null);
    // const [hr, setHr] = useState(props.hazardRate);
    const [uHEstimatedAge, setUHEstimatedAge] = useState(null);

    useEffect(() => {
        getProjectedAge(props.hazardRate, props.age, props.gender)
    }, [props.hazardRate, props.age, props.gender]);
    const getAges = async (hazardRate, age, gender) =>  {
        return await httpClient.post('/risk/v1/getProjectedAge', {
            hazardRate: hazardRate,
            age:age,
            gender:gender,
        });
    }
    const getProjectedAge = async (hazardRate, age, gender) => {
        const ages = await getAges(hazardRate, age, gender);
        const actuarialAges = await getAges(ACTUARIAL_HAZARD, age, gender);
        // setFivePercentileAge(ages.fivePercentileAge)
        setUHEstimatedAge(_.round(age-ages.fiftyPercentileAge+actuarialAges.fiftyPercentileAge,2));
    }
    return (
        <div className={`table-row`}>
            <div className={`${className} w-1/2`}>
                {title}
            </div>
            <div className={`${className} text-right w-1/3`}>
                {displayHazardRate}
            </div>
            <div className={`${className} text-right w-1/6`}>
                {uHEstimatedAge}
            </div>

        </div>
    )
}

// Button.propTypes = {
//     title: PropTypes.string.isRequired,
//     hazardRate:PropTypes.number.isRequired,
// }

export default ProjectedAgeRow;