import { configureStore } from '@reduxjs/toolkit';

import thunk from 'redux-thunk';

import dbListReducer from 'view/list/dbList/reducers';

import authentication from 'view/public/auth/reducers'

import alerts from 'view/common/alert/reducer'

const defaultReducer = {

}

const reducer = {
    dbList: dbListReducer,
    authentication:authentication,
    alert:alerts
}

const middleware = [thunk];
//const middleware = composeWithDevTools(applyMiddleware(...middlewareList));

const store = configureStore({
  reducer:reducer,
  middleware:middleware,
  devTools:process.env.NODE_ENV !== 'production',
  preloadedState:defaultReducer
});

export default store;