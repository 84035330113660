import logo from '../../../img/uh_logo_white.png';

import {logout} from '../../public/auth/actions';
import { useHistory } from "react-router-dom";
import {connect} from 'react-redux';
import Menu from './menu'

const Header = (props) => {

  const history = useHistory();

  const logoutHandler = () => {
    props.logout(history)
  }
  return (
      <nav className="flex items-center  flex-wrap px-2 md:px-3 lg:px-4 xl:px-5 py-1 bg-gradient-to-br  from-uhblue-darkest to-uhblue w-full">

          <Menu
              logout={logoutHandler}
              isLoggedIn={!!props.user}
          />

          <div className="flex items-center flex-shrink-0 text-white mr-6 align-top">
              <a href='/'>
                  <img className='logo' src={logo}  alt="UnitHuman Logo"/>
              </a>
              <a href='/'>
                  <span className={"border-black font-bold text-white mr-10 mb-2.5 inline-block"}>[BETA]</span>
              </a>
          </div>
      </nav>
  )
}


const mapStateToProps = state => ({
    user:state.authentication.user,
})

const mapActionsToProps = {
    logout: logout,
}

export default connect(mapStateToProps, mapActionsToProps)(Header);