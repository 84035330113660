import ProjectedAgeRow from "./projectedAgeRow";
import {useCallback, useEffect, useState} from "react";
import {InputText} from "../common/elements/input";
import Select from "../common/elements/select";
import httpClient from "../../httpClient";
import TopTenRisks from "./topTenRisks";
import _ from "lodash";
import AllBioMarkersChart from "./allBioMarkersChart";

const OutputPanel = (props) => {
    const {
        className,
        age,
        gender,
        topTenRisks,
        showPanel,
        correlation,
        setCorrelation,
        setAge,
        dashboardData
    } = props;
    const tableCellClassName = `table-cell px-2 py-0.5  border-dotted align-middle`;
    const tableHeadCell = `font-bold`;

    const [localAge, setLocalAge] = useState(age);
    const [localGender, setLocalGender] = useState(gender);
    const [localCorrelation, setLocalCorrelation] = useState(0);
    const [cError, setCError] = useState("");
    const [basketHazard, setBasketHazard] = useState(0);

    const calculateBasketHazard = async (correlation) => {
        let response = await httpClient.post('/risk/v1/getBasketHazard', {
            hazards:props.topTenRisks,
            correlation: correlation
        });
        setBasketHazard(_.round(response.basketHazard,2));
        return response.basketHazard;
    }

    useEffect(() => {
        setLocalCorrelation(props.correlation);
        refreshValues();
    },[]);



    const genderOptions = [
        {value:1, label:'Male'},
        {value:2, label:'Female'},
    ];
    let defaultOption;
    for(let i=0;i<genderOptions.length;i++) {
        if(genderOptions[i].value === localGender) {
            defaultOption = genderOptions[i];
        }
    }

    const refreshValues = () => {
        setLocalGender(props.gender);
        setLocalAge(props.age);

    }

    useEffect(() => {
        refreshValues()
    }, [props.age, props.gender]);


    useEffect(async () => {
        await calculateBasketHazard(localCorrelation)
    }, [props.topTenRisks]);

    const genderChange = (selectedOption) => {
        setLocalGender(selectedOption.value)
        props.setGender(selectedOption.value)
    }

    const setCorrelationLocal = async (value) => {
        if(value) {
            value = parseFloat(value);
        } else {
            value=""
        }

        if(value >100 || value<0) {
            setCError("Enter a number between 0 and 100");
            return;
        } else {
            setCError("");
        }
        setLocalCorrelation(value);
        await debouncedGetAndUpdateBasketHazard(value);

    }

    const getAndUpdateBasketHazard = async value => {
        await calculateBasketHazard(value)
        setCorrelation(value)
    }


    const ageChange = async (age) => {
        setLocalAge(age);
        await debouncedAgeChange(age);
    }

    const debouncedAgeChange = useCallback(
        _.debounce(setAge, 500)
        , [setAge]);



    const debouncedGetAndUpdateBasketHazard = useCallback(
        _.debounce(getAndUpdateBasketHazard, 1000)
        , [setCorrelation]);

    const localClassNames=` pl-0 md:pl-2 xl:px-2 overflow-x-scroll  border-l-0 md:border-l  \
    border-t-2 md:border-t-0 border-uhblue-light`;

    if(!showPanel) {
        return (
            <div id="outputPanel" className={`${className} ${localClassNames}`}>
                <div className={`align-middle text-center pt-16 text-gray-800`}>
                    Please enter at least five biomarker values
                </div>
            </div>
        )
    }


    return (
        <div id="outputPanel" className={`${className} ${localClassNames}`}>
            <div className={`font-bold text-gray-800 mb-2`}>
                Projections
            </div>
            <div className={`flex justify-between items-center`}>
                <div className={`inline-block`}>
                    <div className={`inline-block`}>
                        Age:
                    </div>
                    <div className={`w-14 inline-block`}>
                        <InputText
                            value={localAge}
                            onChange={ageChange}
                        />
                    </div>

                </div>

                <div className={`inline-block`}>
                    <div className={`inline-block`}>Gender:</div>
                    <div className={`inline-block`}>
                        <Select
                            value={defaultOption}
                            options={genderOptions}
                            onChange={genderChange}
                            labelKey={'label'}
                            valueKey={`value`}
                            isClearable={false}
                        />
                    </div>

                </div>
                <div className={`inline-block`}>
                    <div className={`inline-block`}>
                        Correlation:
                    </div>
                    <div className={`w-14 inline-block`}>
                        <InputText
                            value={localCorrelation}
                            onChange={setCorrelationLocal}
                            error={cError}
                        />
                    </div>
                    <div className={`inline-block`}>
                        %
                    </div>
                </div>
            </div>

            <div className={`py-4 text-gray-400`}>

                <div className={`inline-block text-xs`}>
                    <div className={`inline-block`}>
                        <input
                            type="radio"
                            name="correlation_type"
                            value={"group"}
                            id={"c_group"}
                            className={`inline-block`}
                            disabled={true}
                            checked={true}
                        />
                        <label htmlFor={"c_group"}>Apply UH Grouped Correlations</label>
                    </div>

                    <div className={`pl-5 inline-block`}>
                        <input
                            type="radio"
                            name="correlation_type"
                            value={"global"}
                            id={"c_global"}

                            disabled={true}
                        />
                        <label htmlFor={"c_global"}>Apply UH Global Correlation</label>
                    </div>
                    {/*<div className={`pl-5 inline-block text-xs`}>*/}
                    {/*    [Not Available]*/}
                    {/*</div>*/}
                </div>
            </div>
            {
                showPanel &&
                <div>
                    <div className={`table w-full`}>
                        <div className={`table-row`}>
                            <div className={`${tableCellClassName} ${tableHeadCell} w-1/2`}>Projected</div>
                            <div className={`${tableCellClassName} ${tableHeadCell} text-right`}>Relative Hazard</div>
                            <div className={`${tableCellClassName} ${tableHeadCell} text-right`}>Age</div>

                        </div>

                        <ProjectedAgeRow
                            hazardRate={basketHazard}
                            title={`UnitHuman Estimate`}
                            age={localAge}
                            gender={localGender}
                            className={tableCellClassName}
                        />
                    </div>


                    <div className={`mt-12`}>
                        <TopTenRisks
                            topTenRisks={topTenRisks}
                            correlation={correlation}
                            age={age}
                            gender={gender}
                        />
                    </div>
                    <div className={`mt-12`}>
                        <AllBioMarkersChart
                            dashboardData={dashboardData}
                        />
                    </div>
                </div>
            }



        </div>
    )
}

export default OutputPanel;