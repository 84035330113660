import {useState, useEffect} from 'react'
import {useParams, useHistory} from "react-router-dom";
import httpClient from 'httpClient/index'
import Input from './input'
import Source from './source'
import Loader from 'view/common/elements/loader'
import Link from 'view/common/elements/link'
import Button from 'view/common/elements/button'
import OutlineButton from 'view/common/elements/outlineButton'

const EditEntry = () => {
    const history = useHistory();
    const [hazard, setHazard] = useState('');
    const [molecule, setMolecule] = useState('');
    const [biomarkers, setBioMarker] = useState('');
    const [organ, setOrgan] = useState('');
    const [intervention, setIntervention] = useState('');
    const [hallmark, setHallmark] = useState('');
    const [impact, setImpact] = useState('');
    const [pathway, setPathway] = useState('');
    const [pillars, setPillars] = useState('');
    const [sens, setSens] = useState('');
    const [syndrome, setSyndrome] = useState('');
    const [system, setSystem] = useState('');
    const [sources, setSources] = useState([]);

    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [deleted, setDeleted] = useState(false);

    const { entryId } = useParams();

    const fetchData = async () => {
        if(!entryId) {
            return;
        }
        setLoading(true);
        const data =  await httpClient.get(`/administration/v1/getEntry?id=${entryId}`)
        setLoading(false);
        setEntry(data);    
    }

    const clearForm = () => {
        setBioMarker('');
        setHallmark('');
        setHazard('');
        setImpact('');
        setIntervention('');
        setMolecule('');
        setOrgan('');
        setPathway('');
        setPillars('');
        setSens('');
        setSources([]);
        setSyndrome('');
        setSystem('');
    }

    const setEntry = entry => {
        if(entry.hazard) {
            setHazard(entry.hazard);
        }
        if(entry.molecule) {
            setMolecule(entry.molecule);
        }
        if(entry.biomarker) {
            setBioMarker(entry.biomarker);
        }
        if(entry.organ) {
            setOrgan(entry.organ);
        }
        if(entry.intervention){
            setIntervention(entry.intervention);
        }
        if(entry.hallmark) {
            setHallmark(entry.hallmark);
        }
        if(entry.impact) {
            setImpact(entry.impact);
        }
        if(entry.pathway) {
            setPathway(entry.pathway);
        }
        if(entry.sens) {
            setSens(entry.sens);
        }
        if(entry.pillars) {
            setPillars(entry.pillars);
        }
        if(entry.syndrome) {
            setSyndrome(entry.syndrome);
        }
        if(entry.system) {
            setSystem(entry.system);
        }
        if(entry.sources) {
            setSources(entry.sources);
        }

        if(entry.delete_date && entry.delete_date !== '0') {
            setDeleted(true);
        } else {
            setDeleted(false);
        }
    }

    useEffect(() => {
        if(!entryId) {
            document.title = 'New Entry';
        } else {
            document.title = `Edit Entry - ${entryId}`
        }
        fetchData()
    },[]);

    const addMoreSource = e => {
        e.preventDefault();
        const newSources = [...sources];
        newSources.push('');
        setSources(newSources);
    }

    const deleteSource = index => {
        const newSources = [...sources];
        newSources.splice(index,1)
        setSources(newSources);
    }

    const editSource = (index, source) => {
        const newSources = [...sources];
        newSources[index] = source;
        setSources(newSources);
    }

    const saveEntry = async (e) => {
        e.preventDefault();
        if(saving) {
            return;
        }
        const entry = {
            hazard:hazard,
            molecule:molecule,
            biomarker:biomarkers,
            organ:organ,
            intervention:intervention,
            hallmark:hallmark,
            impact:impact,
            pathway: pathway,
            sens:sens,
            pillars:pillars,
            syndrome:syndrome,
            system:system,
            sources:sources,
            id:entryId
        };

        

        setSaving(true);
        const data = await httpClient.post('/administration/v1/saveEntry',entry);
        setSaving(false);
        if(!entryId) {
            
            history.push(`/administration/editEntry/${data.id}`);
        }
        
        await fetchData()
    }

    const deleteEntry = async (e) => {
        const payload = {id:entryId};
        setDeleting(true);
        await httpClient.post('/administration/v1/deleteEntry',payload);
        setDeleting(false);
        await fetchData()
    }

    return(
        <div>
            {loading && <Loader/>}
            {
            <form onSubmit={saveEntry}>
                {
                    entryId && 
                    <h2 className="px0 md:px-2 lg:px-4 mb-4 "> 
                        {`Entry #${entryId}`} {deleted && <span className='bg-noti-error text-white text-bold p-1'>[Deleted]</span>} 
                    </h2>
                }
                
                <div className=''>
                    <Input
                        value={hazard}
                        label={'Hazard'}
                        onChange={setHazard}
                    />

                    <Input
                        value={molecule}
                        label={'Molecule'}
                        onChange={setMolecule}
                    />
                    <Input
                        value={organ}
                        label={'Organs'}
                        onChange={setOrgan}
                    />

                    <Input
                        value={biomarkers}
                        label={'Bio Markers'}
                        onChange={setBioMarker}
                    />

                    <Input
                        value={intervention}
                        label={'Interventions'}
                        onChange={setIntervention}
                    />

                    <Input
                        value={hallmark}
                        label={'Hallmarks'}
                        onChange={setHallmark}
                    />
                    <Input
                        value={impact}
                        label={'Impacts'}
                        onChange={setImpact}
                    /> 
                    <Input
                        value={pathway}
                        label={'Pathways'}
                        onChange={setPathway}
                    />
                    <Input
                        value={pillars}
                        label={'Pillars'}
                        onChange={setPillars}
                    />
                    <Input
                        value={sens}
                        label={'SENS'}
                        onChange={setSens}
                    />
                    <Input
                        value={syndrome}
                        label={'Syndromes'}
                        onChange={setSyndrome}
                    />
                    <Input
                        value={system}
                        label={'Systems'}
                        onChange={setSystem}
                    />
                    
                </div>
                <div>
                    <h2 className="px0 md:px-2 lg:px-4"> Sources </h2>
                    {

                        sources.map((source, index)=>{
                            return (<Source
                                value={source}
                                type='text'
                                label={`Source ${index+1}`}
                                onChange={setSources}
                                key={`sources_${index}`}
                                deleteSource={deleteSource}
                                changeSource = {editSource}
                                index={index}
                            />)
                        })
                    }
                    <Link href="/#" onClick={addMoreSource} text="+ Add another source" className="px0 md:px-2 lg:px-4"/> 
                </div>
                <Button
                    text='Save'
                    className="mx-auto mt-10 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 table"
                    loading={saving}
                />
                {
                    entryId && !deleted && <OutlineButton
                    text='Delete'
                    className="mx-auto mt-10 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 table"
                    loading={deleting}
                    onClick={deleteEntry}
                />    
                }
                {
                    entryId && <Link
                     href="/administration/newEntry" text="Add an entry" onClick={clearForm}
                     className='blue mx-auto mt-10 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 table text-center'
                />
                }
                
                
            </form>
            }
        </div>
    )
} 

export default EditEntry;