import {useState, useEffect} from 'react';
import {useParams, useHistory} from "react-router-dom";
import httpClient from 'httpClient/index'
import {InputText} from 'view/common/elements/input'
import { ExternalLinkIcon } from '@heroicons/react/solid'
import { ShareIcon } from '@heroicons/react/outline'
import Link from 'view/common/elements/link'
import Button from 'view/common/elements/button'
import OutlineButton from 'view/common/elements/outlineButton'

import Connection from './connection'

import uuid from 'react-uuid'

import Loader from 'view/common/elements/loader'

const SourceConnections = (props)  => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [connections, setConnections] = useState([]);
    const [source, setSource] = useState();
    const [allTypes, setAllTypes] = useState([]);

    const [deleting, setDeleting] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [saving, setSaving] = useState(false);

    const { sourceId } = useParams();

    const fetchData = async () => {
        setAllTypes(await httpClient.get(`/connections/v1/getAllConnectionTypes`))
        if(!sourceId) { //in case new 
            setSource({
                name:''
            })
            return;
        }
        const apiData =  await httpClient.get(`/connections/v1/getConnections/?sourceId=${sourceId}`)

        setConnections(apiData.data);
        setSource(apiData.source);
        
    }

    const changeConnection = (newConnection) => {

        const newConnections = []
        connections.map( connection => {
            if(connection.id === newConnection.id) {
                newConnections.push(newConnection)
            } else {
                newConnections.push(connection)
            }
        } )

        setConnections(newConnections)
    }

    const changeSource = (sourceName) => {
        const newSource = {...source};

        newSource.name = sourceName

        setSource(newSource);

    }

    const deleteAllConnections = () => {

    }
    

    const deleteConnection = (id) => {
        setDeleting(true);
        const newConnections = [];
        connections.map(connection => {
            if(connection.id !== id) {
                newConnections.push(connection)
            }
        })

        setConnections(newConnections)
        setDeleting(false);
        setDeleted(true);
    }

    const addMoreConnection = (e) => {
        e.preventDefault();

        const newConnection = {};
        newConnection.id=uuid();
        newConnection.from = {id:null, name:'',type:''}
        newConnection.to = {id:null, name:'',type:''}
        newConnection.connectionType = {id:-1}
        

        const newConnections = [...connections];
        newConnections.push(newConnection);


        setConnections(newConnections);
    }

    const saveConnections = async (e) => {
        e.preventDefault();
        const apiData = {
            source:source,
            data:connections
        };

        setSaving(true);
        const response = await httpClient.post(`/administration/v1/saveConnections`, apiData);
        setSaving(false);
        if(!sourceId) {
            
            history.push(`/administration/editSourceConnections/${response.id}`);
        }
    }

    useEffect(async () => {
        setLoading(true);
        if(!sourceId) {
            document.title = 'New Source';
        } else {
            document.title = `Edit Source - ${sourceId}`;
        }
        
        await fetchData()
        setLoading(false);
    },[]);

    return(
        <form onSubmit={saveConnections}>
            <div className='md:px-1 '>
            {loading && <Loader/>}
                
            { source &&
                <div className='flex items-center'>
                    <InputText
                        value={source.name}
                        label={'Source'}
                        onChange={changeSource}
                        className={'w-11/12 inline-block '}
                    />
                    <a href={source.name} target='_blank' className='h-5 md:h-7 w-5 md:w-7  mb-4 mx-2 text-uhblue-darkest inline-block cursor-pointer'> 
                        <ExternalLinkIcon 
                            title='Open link in a new window'
                            className=''
                        />
                    </a>

                    <a href={`/connections/?sourceId=${sourceId}`} target='_blank' className='text-uhblue-darkest inline-block cursor-pointer rotate-90 h-5 md:h-7 w-5 md:w-7 mb-4 mx-2'> 
                        <ShareIcon 
                            title='Open link in a new window'
                            className=''
                        />
                    </a>
                </div>
            }
            </div>

            <div className='md:px-1 mt-10'>
                <h2 className='font-bold text-lg'>Create Connections</h2>
            </div>
            <div>
                { connections && allTypes && connections.length>0 && allTypes.length>0 &&
                    connections.map(connection => {
                        return (
                            <Connection
                                {...connection}
                                allTypes={allTypes}
                                key={`connection_${connection.id}`}
                                onChange={changeConnection}
                                onDelete={deleteConnection}
                            />
                        )
                    })
                }
                <Link href="#" onClick={addMoreConnection} text='+ Add More'/>
            </div>


            <Button
                text='Save'
                className="mx-auto mt-10 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 table"
                loading={saving}
            />
            {
                sourceId && !deleted && <OutlineButton
                text='Delete'
                className="mx-auto mt-10 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 table"
                loading={deleting}
                onClick={deleteAllConnections}
                
            />    
            }
            {
                sourceId && <Link
                 href="/administration/newSourceConnections" text="Create a new Source and Connections" 
                 className='blue mx-auto mt-10 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 table text-center'
            />
            }
        </form>
    )
}

export default SourceConnections;