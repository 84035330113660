import constants from './constants';
const initialState={
    data:[],
    loading:false,
    searching:false,
    searchedData: [],
    searchError: false
}



const dbList = (state = initialState, action) => {
    
    switch(action.type){

        case 'GET_USERS':
            return {
                ...state,
                users:action.payload,
                loading:false

            }
        case 'SEARCH_DATA_REQUEST':
            return {
                ...state,
                searching:true,
                searchError: false
            }
        case 'SEARCH_DATA_SUCCESS':
            const searchError = action.entries && action.entries.length===0
            return {
                ...state,
                searching:false,
                searchedData: action.entries,
                searchError: searchError? 'No entries found':false
            }
        case 'SEARCH_DATA_FAILURE': 
            return {
                ...state,
                searching:false,
                searchError:action.msg
            }
        case constants.CLEAR_SEARCH_ERROR:
            return {
                ...state,
                searchError:false
            }
        case constants.CLEAR_SEARCH:
            return {
                ...state,
                searchedData:[],
                searching:false,
                searchError:false
            }
        default: return state
    }

}

export default dbList;