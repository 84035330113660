import RouteLink from 'view/common/elements/routeLink'
import {useRouteMatch} from 'react-router-dom'

const Item = (props) => {

    const match = useRouteMatch({
        path:props.to,
        exact:true
    });

    let allClasses = "block  mt-2  font-bold";

    if(match) {
        allClasses += " active"
    }

    return(
        <RouteLink href={props.to} className={allClasses} text={props.label} onClick={props.onClick} />
            
        
    );

    
}

export default Item;