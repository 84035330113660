import  ModalLib from '@mui/material/Modal';
import PropTypes from "prop-types";

const Modal = (props) => {
    const {open, children, onClose, top, left} = {top:50, left:50, ...props};

    const modalStyle = {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(${left-100}%, ${top-100}%)`,
        position:`absolute`
    }

    const handleClose = () => {
        if(onClose) {
            onClose();
        }
    }

    const closeButtonStyle = {
        top:'8px',
        right:'15px',
        cursor:'pointer',
    }

    return (
        <div>
            <ModalLib
                open={open}
                onClose={handleClose}
            >
                <div style={modalStyle} className={`p-10 bg-white relative`}>
                    <div onClick={onClose} className={`absolute`} style={closeButtonStyle}>x</div>
                    {children}
                </div>
            </ModalLib>
        </div>
    );
}

Modal.propTypes = {
    open:PropTypes.bool.isRequired,
    children:PropTypes.element.isRequired,
    onClose:PropTypes.func.isRequired,
}

export default Modal;