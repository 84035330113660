const constants = {
    GET_DATA_REQUEST:'GET_DATA_REQUEST',
    GET_DATA_SUCCESS:'GET_DATA_SUCCESS',
    GET_DATA_ERROR:'GET_DATA_ERROR',
    SEARCH_DATA_REQUEST:'SEARCH_DATA_REQUEST',
    SEARCH_DATA_SUCCESS:'SEARCH_DATA_SUCCESS',
    SEARCH_DATA_ERROR:'SEARCH_DATA_ERROR',
    CLEAR_SEARCH:'CLEAR_SEARCH',
    CLEAR_SEARCH_ERROR:'CLEAR_SEARCH_ERROR'
};

export default constants;