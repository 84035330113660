import React,{useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {InputText} from '../../common/elements/input';
import Button from '../../common/elements/button';
import RouteLink from 'view/common/elements/routeLink';
import './auth.css'
import logo from '../../../img/uh_logo_bullet.png'

import {login, clearAuthError} from './actions'

import { useHistory, useLocation } from "react-router-dom";
import validator from 'validator';

import {formClasses} from './styles';

const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
const Login = (props) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [usernameErr, setUsernameErr] = useState("");
    const [passwordErr, setPasswordErr] = useState("");
    
    const history = useHistory();
    const query = useQuery();
    const changeUsername = (value) => {
        setUsername(value);
    }

    const changePassword = (value) => {
        setPassword(value);
    }

    const loginSubmit = async () => {
        if(props.authentication.authFormBusy) {
            return;
        }
        let error = false;
        if(!password) {
            setPasswordErr("Please enter your password")
            error=true;
        } 

        if(!username || !validator.isEmail(username)) {
            setUsernameErr("Please enter your email address")
            error=true;
        }

        if(error) {
            return;
        }
        // await service.login(username, password);
        props.login(username, password, history, query.get("redirect"));
    }

    const clearEmailError = () => {
        setUsernameErr();
        props.clearAuthError()
    }

    useEffect(()=>{
        document.title='Login'
    })

    return (
        <div id="loginContainer" className="authContainer"> 
            <form className={`w-80 md:w-96 text-center ${formClasses}`}>
                <div className='logoContainer'>
                    <img src={logo}  alt="UnitHuman Logo"/>
                </div>
                <InputText 
                    name="email" 
                    id="loginUsername" 
                    onChange={changeUsername}
                    placeholder="Email"
                    error={usernameErr||props.authentication.authFormError}
                    onFocus={clearEmailError}
                    className={"mb-2 padded"}
                    value={username}
                />
                <InputText 
                    name="password" 
                    id="loginPassword" 
                    type="password"
                    onChange={changePassword}
                    placeholder="Password"
                    error={passwordErr}
                    onFocus={()=>setPasswordErr()}
                    className={"mb-2 padded"}
                    value={password}
                />
                <Button 
                    className={`blue relative`}
                    text="Login"
                    loading={props.authentication.authFormBusy}
                    onClick={loginSubmit}
                /> 
                     
                <span className="block mt-3">
                    <RouteLink href="/auth/forgotPassword" text="Forgot Password" className='blue'/>
                </span>
                <span className='block mt-1'>
                    Not a member? <RouteLink href="/auth/register" text="Sign Up!" className='blue'/>
                </span>
            </form>
        </div>
    )
}

const mapStateToProps = state => ({
    authentication:state.authentication,
})

const mapActionsToProps = {
    login: login,
    clearAuthError:clearAuthError
}

export default connect(mapStateToProps, mapActionsToProps)(Login);

