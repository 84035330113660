import BiomarkerInput from "./biomarkerInput";
const InputPanel = (props) => {
    const {dashboardData, className, onChange} = props;
    const localClassnames="pr-0 md:pr-2 overflow-x-scroll  border-b-2 border-uhblue-light md:border-b-0 mb-2 md:mb-0";
    return (
        <div id="inputValues" className={`${className} ${localClassnames}`}>
            <div className={`font-bold text-gray-800 mb-2`}>
                Biomarkers
            </div>
            <div className={`table w-full`}>

                {
                    dashboardData.map((biomarker, index) => {

                        return (
                            <BiomarkerInput
                                key={biomarker.biomarkerId}
                                biomarkerId={biomarker.biomarkerId}
                                biomarker={biomarker.biomarker}
                                unitId={biomarker.unitId}
                                unit={biomarker.unit}
                                hazardRate={biomarker.hazardRate}
                                onChange={onChange}
                                value={biomarker.value}
                                outOfBounds={biomarker.outOfBounds}
                                minValue={biomarker.minValue}
                                maxValue={biomarker.maxValue}
                            />
                        )
                    })
                }
            </div>
            {/*<Button*/}
            {/*    text={`Submit`}*/}
            {/*    onClick={submitData}*/}
            {/*/>*/}

        </div>
    )
}



export default InputPanel;