
import React from "react";
import { Redirect, Route } from "react-router"; 
import {getToken} from '../../../helpers/tokenStore'
import PropTypes from "prop-types";



const AuthRoute = (props) => {
  const token = getToken();

  if(token && token !== "undefined" && props.loggedIn) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
};

AuthRoute.propTypes = {
  loggedIn : PropTypes.bool.isRequired
}


export default AuthRoute;