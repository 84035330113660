import {useState} from "react";
import httpClient from "../../httpClient";
import {InputReset, InputSubmit} from "../common/elements/input";
import axios from 'axios';


const UploadFile = (props) => {
    const {onFileUploadSuccess, onFileUploadError} = props;
    const [filename, setFilename] = useState("");
    const [fileSelected, setFileSelected] = useState(false);
    const [fileUploading, setFileUploading] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);
    const [fileUploaded, setFileUploaded] = useState(false);
    const onUploadFileChange = (e) => {

        console.log(`change ${e.target.value}`);
        const newFilename = e.target.value;
        setFilename(newFilename);
        if(!newFilename || newFilename === "") {
            setFileSelected(false);
            console.log(`c12`);
        } else {
            setCurrentFile(e.target.files[0]);
            setFileSelected(true);
            console.log(`c132`);
        }

    }
    const onFormClear = () => {
        setFilename("");
        setFileSelected(false);
    }
    const onFileUpload = async () => {

    }


    const onFormSubmit = async (e) => {
        e.preventDefault();

        if(!filename || filename === "") {
            return;
        }
        setFileUploading(true);
        const response = await httpClient.get(`/risk/v1/getUploadURL?filename=${filename}`);

        try {
            await axios({
                method: "put",
                url: response.uploadURL,
                data: currentFile,
                headers: { 'Content-Type': "multipart/form-data" },
            });
            setFileUploading(false);
            setFileUploaded(true)
            onFileUploadSuccess(response.Key);
        } catch(error) {
            setFileUploading(false);
            onFileUploadError(response, error);
        }
    }

    if(fileUploaded) {
        return (
            <div  style={{width:"345px", height:"85px"}}>
                <div className={`text-green-600 text-center font-bold`}>
                    File Uploaded Successfully. Your data will be displayed shortly.
                </div>
            </div>
        )
    }

    return (
        <div style={{width:"345px", height:"85px"}}>
            <form onSubmit={onFormSubmit}>
                {/* TODO replace with drop zone */}

                <div className={`w-full border-dashed border p-1 border-uhblue-darkest`}>
                    <input
                        type='file'
                        name='uploadedPortfolio'
                        onChange={onUploadFileChange}
                        accept=".csv"
                    />
                </div>
                <div className={`mt-3`}>
                    <InputSubmit
                        onClick={onFileUpload}
                        text={`Upload`}
                        disabled={!fileSelected}
                        loading={fileUploading}
                        type={`submit`}
                    />

                    <InputReset
                        value={`Clear`}
                        onClick={onFormClear}
                        type={`reset`}
                        className={`ml-3 text-blue`}
                    />
                    {/*<Link onClick={toggleVis} text={`T`} />*/}

                </div>
            </form>


        </div>
    )
}

export default UploadFile;