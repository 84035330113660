import constants from './constants';
import {getUser, getPermissions} from 'helpers/tokenStore'

let user = getUser();
const initialState = user ? { loggedIn: true, user, permissions:getPermissions() } : {loggedIn: false};

export default function authentication(state = initialState, action) {
    
    switch (action.type) {
    case constants.LOGIN_REQUEST:
    case constants.REG_REQUEST:
    case constants.FP_REQUEST:
        return {
            ...state,
            authFormBusy: true,
        };
    case constants.LOGIN_SUCCESS:
        return {
            ...state,
            loggedIn: true,
            user: action.user.user,
            permissions: action.user.permissions,
            authFormBusy: false,
        };
    case constants.LOGIN_FAILED:
    case constants.REG_FAILED:
    case constants.FP_FAILED:
        return {
            ...state,
            authFormError: action.err,
            authFormBusy: false,
        };
    case constants.LOGOUT:
        return {loggedIn: false};
    case constants.FP_SUCCESS: 
        return {
            ...state,
            fpSuccessMessage:action.data,
            authFormBusy: false,
        }
    case constants.CLEAR_ERROR:
        return {
            ...state,
            authFormError:null
        }
    case constants.VERIFY_TOKEN_REQUEST:
        return {
        ...state,
        tokenVerified:false,
        tokenVerificationFailed: false,
        tokenVerifying:true,
    }
    case constants.VERIFY_TOKEN_FAILED:
    return {
        ...state,
        tokenVerificationFailed:true,
        tokenVerifying:false,
        tokenVerified:false,
    }
    case constants.VERIFY_TOKEN_SUCCESS:
    return {
        ...state,
        tokenVerified:action.data.valid,
        tokenVerificationFailed: false,
        tokenVerifying:false
    }
    case constants.RESET_PASSWORD_REQUEST: 
    return {
        ...state,
        authFormBusy:true,
        authFormError:false,
        rpSuccessMsg:false,
    }
    case constants.RESET_PASSWORD_SUCCESS: 

    return {
        ...state,
        authFormBusy:false,
        authFormError:false,
        rpSuccessMsg: 'Your password has been reset. You\'ll be automatically taken to the login page.'
    }
    case constants.RESET_PASSWORD_FAILED: 
    return {
        ...state,
        authFormBusy:false,
        authFormError:action.err,
        rpSuccessMsg:false
    }
    case 'USER_LOGGED_OUT': 
    return {
        ...state,
        loggedIn:false,
    }
    return {
        ...state
    }
    default:
        return state
    }
}