import React from 'react';
import './tailwind/built.css';
import {connect} from 'react-redux';
// import PublicView from './view/public/index'
import MainView from './view/mainView'
import Footer from './view/common/footer'
import {PermissionProvider} from './contexts/permissionContext'

// import tokenStore from './helpers/tokenStore'


require('dotenv').config()

const App = (props) => {

  return (
      <React.Fragment>
        <PermissionProvider value={props.permissions}>
          <MainView/>
        </PermissionProvider>
        <Footer/>
      </React.Fragment>
  );
}

const mapStateToProps = state => ({
    permissions:state.authentication.permissions,
})


export default connect(mapStateToProps, {})(App);