import {InputText} from 'view/common/elements/input'

const Input = (props) => {
    return (
        <div className={`w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 inline-block px-0 md:px-2 lg:px-4`}>
            <InputText
                        
                className='inline-block w-full mb-3.5'
                {...props}
            />
        </div>
    )
}
export default Input;