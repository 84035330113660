import constants from './constants';


const initialState = {
    info:null,
    error:null,
    success:null
};

export default function authentication(state = initialState, action) {
    switch (action.type) {

    case constants.SUCCESS:
        return {
            success:action.message
        }
    case constants.INFO:
        return {
            info:action.message
        }
    case constants.ERROR:
        return {
            success:action.message
        }
    default:
        return state
    }
}