
import React from 'react';
import {buttonCommon, outlineButton} from "./classNames";

const OutlineButton = ({text,onClick,className, disabled, loading, type}) => {
    
    let loadingClass='';
    let isDisabled = false;
    let disabledClass='';

    if(loading && !disabled) {
        
        loadingClass="loading"
    }

    if(disabled) {
        isDisabled = true;
        disabledClass = 'disabled';
        
        loadingClass=''
    }
    const click = e => {

        if(onClick===undefined || !onClick) {
            return
        }
        e.preventDefault();
        if(loading||disabled) {
            return
        }
        
        onClick(e.target);
    }
    return (
        <button 
            onClick={click}
            className={`${buttonCommon} ${outlineButton} ${className} ${disabledClass} ${loadingClass}`}
            disabled={isDisabled}
            type={type}
        >

            {text}
            
        </button>
        
    );
}

export default OutlineButton;