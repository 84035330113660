import React,{useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {InputText} from '../../common/elements/input';
import Button from '../../common/elements/button';
import RouteLink from 'view/common/elements/routeLink';
import './auth.css'
import logo from '../../../img/uh_logo_bullet.png'

import {register, clearAuthError} from './actions'

import { useHistory } from "react-router-dom";
import validator from 'validator';
import {formClasses} from './styles';

const  Register = (props) => {

    const history = useHistory();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");

    const [usernameErr, setUsernameErr] = useState('Registrations are on hold. Please contact info@unithuman.co');
    const [passwordErr, setPasswordErr] = useState("");
    const [nameErr, setNameErr] = useState("");

    const changeUsername = (value) => {
        setUsername(value);
    }

    const changePassword = (value) => {
        setPassword(value);
    }
    const regSubmit = async () => {
        let error=false;

        if(props.authentication.authFormBusy) {
            return;
        }
        
        if(!username || !validator.isEmail(username)) {
            setUsernameErr("Please enter your email id")
            error=true;
        }

        if(!name) {
            setNameErr("Please enter your name")
            error=true;
        }

        if(!password) {
            setPasswordErr  ("Please choose a password")
            error=true;
        }

        if(error) {
            return;
        }

        await props.register({name,password,username,history});
    }

    useEffect(()=>{
        document.title = 'Register';
    })
    return (
        <div id="regContainer" className="authContainer"> 
            <form className={`w-80 md:w-96 text-center ${formClasses}`}>
                <div className='logoContainer'>
                    <img src={logo}  alt="UnitHuman Logo" />
                </div>
                <InputText 
                    name="name" 
                    id="regName" 
                    onChange={setName}
                    placeholder="Name"
                    error={nameErr}
                    onFocus={()=>setNameErr()}
                    className={"mb-2 padded"}
                    value={name}
                />
                <InputText 
                    name="email" 
                    id="regEmail" 
                    onChange={changeUsername}
                    placeholder="Email"
                    error={usernameErr||props.authentication.authFormError}
                    onFocus={()=>{setUsernameErr();props.clearAuthError();}}
                    className={"mb-2 padded"}
                    value={username}
                />
                <InputText 
                    name="password" 
                    id="regPassword" 
                    type="password"
                    onChange={changePassword}
                    placeholder="Password"
                    error={passwordErr}
                    onFocus={()=>setPasswordErr()}
                    className={"mb-2 padded"}
                    value={password}
                />
                <Button
                    className={`blue relative`}
                    text="Sign Up"
                    loading={props.authentication.authFormBusy}
                    onClick={regSubmit}
                /> 

                <div className="mt-3">
                
                    Already registered? 
                    <RouteLink
                        className='blue'
                        href="/auth/login"
                        text=" Login"
                    />.
                </div>

            </form>
        </div>
    )
}

const mapStateToProps = state => ({
    authentication:state.authentication,
})

const mapActionsToProps = {
    register: register,
    clearAuthError:clearAuthError
}

export default connect(mapStateToProps, mapActionsToProps)(Register);