import PropTypes from "prop-types";


const Link = ({href, text, target, className, onClick}) => {
    
    return (
        <a 
            href={href}
            target={target}
            className={`text-uhblue ${className}`}
            onClick={e=>onClick(e)}
        > 
            {text} 
        </a>
    );
}
Link.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
}
export default Link;