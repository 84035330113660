import logo from '../../../img/loaderblue.svg'
import React from 'react';

const Loader = (props) => {
    let {className, mainLoader} = {mainLoader:false,...props};
    if(!className) {
        className='small'
    }
    if(mainLoader) {
        className = className+" mainLoader ";
    }
    return (
        <React.Fragment>
            <img src={logo} alt="UnitHuman Loader Blue" className={`loader ${className} `} />
        </React.Fragment>
    )
}

export default Loader;