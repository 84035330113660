import constants from './constants';
import service from './service';


const DEFAULT_REDIRECT = '/';

export const login = (username, password, history, redirect="/") =>  async (dispatch) => {
        if(!redirect || redirect === DEFAULT_REDIRECT) {
            redirect='/risk';
        }
        dispatch({ type: constants.LOGIN_REQUEST, username });

        try {
            const user = await service.login(username, password);
        
            dispatch( { type: constants.LOGIN_SUCCESS, user } );

            history.push(redirect);

        } catch (err) {
            dispatch( { type: constants.LOGIN_FAILED, err } );
        }
        
}

export const register = (props) =>  async (dispatch) => {

        const {username,history} = props;

        dispatch({ type: constants.REG_REQUEST, username });

        try {
            const user = await service.register(props);
        
            dispatch( { type: constants.LOGIN_SUCCESS, user } );
            
            history.push(DEFAULT_REDIRECT);
        } catch (err) {
            dispatch( { type: constants.REG_FAILED, err } );
        }
        
}

export const forgotPassword = (emailId) => async (dispatch) => {
    dispatch({type: constants.FP_REQUEST, emailId});
    try {
            const data = await service.forgotPassword(emailId);
        
            dispatch( { type: constants.FP_SUCCESS, data } );
    } catch (err) {
        dispatch( { type: constants.FP_FAILED, err } );
    }
}

export const logout = (history) =>  async (dispatch) => {
    dispatch( { type: constants.LOGOUT } );
    await service.logout();
    history.push('/auth/login');
}

export const clearAuthError = () => async (dispatch) => {
    dispatch({type:constants.CLEAR_ERROR}); 
}


export const verifyResetPasswordToken = (token) => async (dispatch) => {
    dispatch({type: constants.VERIFY_TOKEN_REQUEST, token});
    try {
        const data = await service.verifyResetPasswordToken(token);
    
        dispatch( { type: constants.VERIFY_TOKEN_SUCCESS, data } );
    } catch (err) {
        dispatch( { type: constants.VERIFY_TOKEN_FAILED, err } );
    }
} 

export const saveNewPassword = ({token, password,confirmPassword, history}) => async (dispatch) => {
    dispatch({type: constants.RESET_PASSWORD_REQUEST, token});
    try {
        const data = await service.saveNewPassword({token, password,confirmPassword});
    
        dispatch( { type: constants.RESET_PASSWORD_SUCCESS, data } );
        setTimeout(()=>{history.push('/auth/login');},7000);
    } catch (err) {
        dispatch( { type: constants.RESET_PASSWORD_FAILED, err } );
    }
    
} 
