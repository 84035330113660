import React,{useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {InputText} from 'view/common/elements/input';
import Button from 'view/common/elements/button';
import RouteLink from 'view/common/elements/routeLink';
import './auth.css'
import logo from 'img/uh_logo_bullet.png'
import { useLocation, useHistory } from "react-router-dom";
import Loader from 'view/common/elements/loader'
import {formClasses} from './styles';

import {
    verifyResetPasswordToken, 
    saveNewPassword, 
    clearAuthError
} from './actions';


const NewPassword = (props) => {

    const history = useHistory();
    const search = useLocation().search;
    const token=new URLSearchParams(search).get("token");

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);


    const confirmPasswordSubmit = async () => {

        if(props.authentication.authFormBusy) {
            return;
        }
        if(password === '') {
            setPasswordError('Please enter your new password');
            return;
        }
        if(password.length < 6) {
            setPasswordError('Your password should be atleast 6 characters long');
            return;
        }

        if(confirmPassword === '') {
            setConfirmPasswordError('Please confirm your new password');
            return;
        }

        if(password !== confirmPassword) {
            setConfirmPasswordError("Passwords do not match");
            return;
        }
        await props.saveNewPassword({token, password,confirmPassword, history});

    }

    const verifyToken = () => {
        props.verifyResetPasswordToken(token)
    }

    useEffect(() => {
        document.title = 'Set new password';
         verifyToken()
    },[]);

    return (
    <React.Fragment>
    {props.authentication.tokenVerifying && <Loader/>}
    {!props.authentication.tokenVerifying && !props.authentication.tokenVerified && 
        <div> 
            The link could not be verified. 
            Please <RouteLink href="/forgotPassword" text="click here" className='blue'/> to generate a new link to reset your password.
        </div>
    }
    { !props.authentication.tokenVerifying && props.authentication.tokenVerified &&
        <div id="npContainer" className="authContainer"> 
            <form className={`w-80 md:w-96 text-center ${formClasses}`}>
                <div className='logoContainer'>
                    <img src={logo}  alt="UnitHuman Logo"/>
                </div>
                <InputText 
                    value={password}
                    name="password" 
                    id="password" 
                    onChange={setPassword}
                    type='password'
                    placeholder="Password"
                    error={passwordError||props.authentication.authFormError}
                    onFocus={()=>{setPasswordError(); props.clearAuthError()}}
                    className={"mb-2 padded"}
                />

                <InputText 
                    value={confirmPassword}
                    name="confirmPassword" 
                    id="confirmPassword" 
                    onChange={setConfirmPassword}
                    placeholder="Confirm Password"
                    type='password'
                    error={confirmPasswordError}
                    onFocus={()=>{setConfirmPasswordError() ;}}
                    info={props.authentication.rpSuccessMsg}
                    className={"mb-2 padded"}
                />
                
                <Button
                    className={`blue relative`}
                    text="Reset your password"
                    disabled={props.authentication.fpSuccessMessage}
                    loading={props.authentication.authFormBusy}
                    onClick={confirmPasswordSubmit}
                /> 
                     
                <span className="block mt-3">
                    <RouteLink className='blue' href="/auth/login" text="Login"/>
                </span>
                <span className='block mt-1'>
                    Not a member? <RouteLink className='blue' href="/auth/register" text="Sign Up!"/>
                </span>
            </form>
        </div>
    }
    </React.Fragment>
    )
}

const mapStateToProps = state => ({
    authentication:state.authentication,
})

const mapActionsToProps = {
    saveNewPassword: saveNewPassword,
    verifyResetPasswordToken: verifyResetPasswordToken,
    clearAuthError: clearAuthError
}

export default connect(mapStateToProps, mapActionsToProps)(NewPassword);

