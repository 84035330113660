import {Route, Switch, useRouteMatch} from "react-router-dom";

import Login from 'view/public/auth/login'
import Register from 'view/public/auth/register'
import ForgotPassword from 'view/public/auth/forgotPassword'
import NewPassword from 'view/public/auth/newPassword'

const  Auth = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path } = useRouteMatch();
  return (
    <div>
        <Switch>
            <Route path={`${path}/register`}>
                <Register />
            </Route>
            <Route path={`${path}/login`}>
                <Login />
            </Route>
            <Route path={`${path}/forgotPassword`}>
                <ForgotPassword />
            </Route>
            <Route path={`${path}/resetPassword`}>
                <NewPassword />
            </Route>
        </Switch>
    </div>
    );
}

export default Auth;