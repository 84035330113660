import {InputText} from 'view/common/elements/input'
import { TrashIcon } from '@heroicons/react/solid'
import { ExternalLinkIcon } from '@heroicons/react/solid'

const Source = (props) => {
    const onChange = (source) => {
        props.changeSource(props.index, source)
    }
    const onDelete = () => {
        props.deleteSource(props.index)
    }
    console.log(props);
    return (
        <div className={`w-full inline-block px0 md:px-2 lg:px-4 flex flex-wrap content-start`}>
            <InputText      
                className='inline-block w-11/12'
                {...props}
                onChange={onChange}
            />
            <div className='w-1/12 inline-block flex flex-wrap content-center grid justify-items-end mb-4'>
                <a href={props.value} target='_blank' rel="noopener noreferrer">
                    <ExternalLinkIcon className='h-5 md:h-7 w-5 md:w-7  text-uhblue-darkest inline-block cursor-pointer'/>
                </a>
                <TrashIcon 
                    className='h-5 md:h-7 w-5 md:w-7  text-uhblue-darkest inline-block cursor-pointer'
                    onClick={onDelete}
                />
            </div>
        </div>
    )
}
export default Source;