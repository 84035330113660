import React,{useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {InputText} from '../../common/elements/input';
import Button from '../../common/elements/button';
import RouteLink from 'view/common/elements/routeLink';
import './auth.css'
import logo from '../../../img/uh_logo_bullet.png'
import {forgotPassword, clearAuthError} from './actions'
import validator from 'validator';
import {formClasses} from './styles';


const ForgotPassword = (props) => {

    const [email, setEmail] = useState("");
    const [emailErr, setEmailErr] = useState("");

    const fpSubmit = () => {
       if(props.authentication.authFormBusy) {
            return;
        }

        let error = false;

        if(!email || !validator.isEmail(email)) {
            setEmailErr("Please enter your email id")
            error=true;
        }

        if(error) {
            return;
        }

        props.forgotPassword(email);
    }

    useEffect(()=>{
        document.title = 'Forgot Password';
    })
    

    return (

        <div id="fpContainer" className="authContainer"> 
            <form className={`w-80 md:w-96 text-center ${formClasses}`}>
                <div className='logoContainer'>
                    <img src={logo}  alt="UnitHuman Logo"/>
                </div>
                <InputText 
                    name="email" 
                    id="loginUsername" 
                    onChange={setEmail}
                    placeholder="Email"
                    error={emailErr||props.authentication.authFormError}
                    onFocus={()=>{setEmailErr();props.clearAuthError()}}
                    info={props.authentication.fpSuccessMessage}
                    className={"mb-2 padded"}
                    value={email}
                />
                
                <Button
                    className={`blue relative`}
                    text="Get Password Reset Link"
                    disabled={props.authentication.fpSuccessMessage}
                    loading={props.authentication.authFormBusy}
                    onClick={fpSubmit}
                /> 
                     
                <span className="block mt-3">
                    <RouteLink className='blue' href="/auth/login" text="Login"/>
                </span>
                <span className='block mt-1'>
                    Not a member? <RouteLink className='blue' href="/auth/register" text="Sign Up!"/>
                </span>
            </form>
        </div>
    );
}

const mapStateToProps = state => ({
    authentication:state.authentication,
})

const mapActionsToProps = {
    forgotPassword: forgotPassword,
    clearAuthError: clearAuthError
}

export default connect(mapStateToProps, mapActionsToProps)(ForgotPassword);