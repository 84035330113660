const constants = {
    LOGIN_REQUEST:"LOGIN_REQUEST",
    LOGIN_SUCCESS:"LOGIN_SUCCESS",
    LOGIN_FAILED:"LOGIN_FAILED",
    LOGOUT:"LOGOUT",
    REG_REQUEST:"REG_REQUEST",
    REG_SUCCESS:"REG_SUCCESS",
    REG_FAILED:"REG_FAILED",
    FP_REQUEST:"FP_REQUEST",
    FP_SUCCESS:"FP_SUCCESS",
    FP_FAILED:"FP_FAILED",
    CLEAR_ERROR:"CLEAR_ERROR",
    VERIFY_TOKEN_REQUEST:"VERIFY_TOKEN_REQUEST",
    VERIFY_TOKEN_SUCCESS:"VERIFY_TOKEN_SUCCESS",
    VERIFY_TOKEN_FAILED:"VERIFY_TOKEN_FAILED",
    RESET_PASSWORD_REQUEST:"RESET_PASSWORD_REQUEST",
    RESET_PASSWORD_SUCCESS:"RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILED:"RESET_PASSWORD_FAILED",
    
}

export default constants;