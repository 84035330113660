import axios from 'axios';
import getHeaders from '../helpers/headers'
import  store  from 'store/store';
const {dispatch} = store;

const instance = axios.create();

instance.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
// instance.defaults.headers.post['Content-Type'] = 'application/json';
// instance.defaults.headers.get['Content-Type'] = 'application/json';



const httpClient = {};

httpClient.get = async (url, additionalHeaders={}) => {
    const headers = getHeaders();
    try {
        const response = await instance.get(url,{headers});
        return(response.data);
    } catch (err) {
        
        handleError(err);
        return {
            'error':err
        }
    }
}

httpClient.post = async (url, data, additionalHeaders={}) => {

    const headers = getHeaders();
    try {
        const response = await instance.post(url, data, {headers});
        
        return(response.data);
    } catch (err) {
    
        handleError(err);
        return {
            'error':err
        }
    }
}

httpClient.put = async (url, data, additionalHeaders={}) => {

    const headers = getHeaders();
    const allHeaders = {...headers, ...additionalHeaders};
    try {
        const response = await instance.put(url, data, {headers:allHeaders});

        return(response.data);
    } catch (err) {

        handleError(err);
        return {
            'error':err
        }
    }
}

httpClient.delete = async (url) => {
    const headers = getHeaders();
    try {
        const response = await instance.get(url,{headers});
        return(response.data);
    } catch (err) {

        handleError(err);
        return {
            'error':err
        }
    }
}


const handleError = err => {
    if(err.response.status === 401) {
        dispatch({type:'USER_LOGGED_OUT'})
    }
}

export default httpClient;