export const getToken = () => {
    return localStorage.getItem('token')
}

export const setToken = token => {
    if(typeof token == 'undefined') {
        return false;
    }
    localStorage.setItem('token', token);
}

export const removeToken = () => {
    localStorage.removeItem('token');
}


export const getUser = () => {
    return JSON.parse (localStorage.getItem('user'))
}

export const setUser = user => {
    if(typeof user == 'undefined') {
        return false;
    }
    localStorage.setItem('user', JSON.stringify(user));
}

export const removeUser = () => {
    localStorage.removeItem('user');
}

export const removeAll = () => {
    removeUser();
    removeToken();
    removePermissions();
}

export const getPermissions = () => {
    return JSON.parse (localStorage.getItem('permissions'))
}

export const setPermissions = permissions => {
    if(typeof permissions == 'undefined') {
        return false;
    }
    localStorage.setItem('permissions', JSON.stringify(permissions));
}

export const removePermissions = () => {
    localStorage.removeItem('permissions');
}
