import {Route, Switch, useRouteMatch} from "react-router-dom";

import EditEntry from './editEntry/index';

import SourceConnections from './editSourceConnections/index'

const  Administration = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path } = useRouteMatch();

  return (
    <div>
        <Switch>
            <Route exact path={path}>
                <h3>Administration.</h3>
            </Route>
            <Route path={`${path}/editEntry/:entryId`}>
                <EditEntry />
            </Route>
            <Route path={`${path}/newEntry`}>
                <EditEntry />
            </Route>
            <Route path={`${path}/editSourceConnections/:sourceId`}>
                <SourceConnections />
            </Route>
            <Route path={`${path}/newSourceConnections`}>
                <SourceConnections />
            </Route>
        </Switch>
    </div>
    );
}

export default Administration;