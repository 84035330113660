import httpClient from 'httpClient/index';
import {setToken, removeToken, setUser, removeUser, setPermissions, removePermissions} from 'helpers/tokenStore';
const service = {}

const storeUserCredentials = (data) => {
    setToken(data.token);
    setUser(data.user);
    setPermissions(data.permissions);
}

service.login = async (username, password) => {
    const data = await httpClient.post('/auth/v1/login',{
        'emailId':username,
        'password':password
    });

    if(data.error) {
        throw data.error.msg;
    }

    storeUserCredentials(data);

    return data;
}

service.register = async ({name, username, password, phone}) => {
    const data = await httpClient.post('/auth/v1/register',{
        'emailId':username,
        'password':password,
        "name":name,
        "phone":phone, 
    });

    if(data.error) {
        throw data.error.msg;
    }

    storeUserCredentials(data);

    return data;
}

service.forgotPassword = async emailId => {
    const data = await httpClient.post('/auth/v1/forgotPassword',{
        'emailId':emailId,
    });

    if(data.error) {
        throw data.error.msg;
    }

    return data.msg;
}

service.verifyResetPasswordToken = async token => {
    const data = await httpClient.post('/auth/v1/verifyResetPasswordToken',{
        'token':token,
    });

    if(data.error) {
        throw data.error.msg;
    }

    return data;
}

service.saveNewPassword = async ({token, password,confirmPassword}) => {
    const data = await httpClient.post('/auth/v1/saveNewPassword',{
        'token':token,
        'password':password,
        'confirmPassword':confirmPassword
    });

    if(data.error) {
        throw data.error.msg;
    }

    return data;
    
}

service.logout = async () => {
    removeToken(); 
    removeUser();  
    removePermissions();
}

export default service