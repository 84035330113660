
import RiskOutputPanel from "./riskOutputPanel";

const TopTenRisks = (props) => {
    const {
        topTenRisks,
        age,
        gender,
        correlation
    } = props;
    // const tableCellClassName = `table-cell px-2 border-b-2 border-dotted align-middle`;
    const tableCellClassName =` table-cell px-2 align-middle`
    return (
        <div>
            <div className={`font-bold text-gray-800 ${tableCellClassName} border-0`}> Worst 10 hazards</div>
            <div className={`table w-full`}>
                {
                    topTenRisks.map((risk,idx)=>{
                        if(risk.hazardRate >0 )
                            return (
                                <RiskOutputPanel
                                    key={`OutputRow_${risk.biomarkerId}`}
                                    topTenRisks={topTenRisks}
                                    biomarker={risk.biomarker}
                                    biomarkerId={risk.biomarkerId}
                                    unit={risk.unit}
                                    unitId={risk.unitId}
                                    value={risk.value}
                                    hazardRate={risk.hazardRate}
                                    age={age}
                                    gender={gender}
                                    correlation={correlation}
                                />

                            )
                    })
                }
            </div>
        </div>
    )
}

export default TopTenRisks;