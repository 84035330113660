export const getChartOptions = (chartTitle, hAxisTitle, vAxisTitle) => {
    return {
        series: {
            0: {color: '#246A9A'},
        },
        chart :{
            title:`${chartTitle}`,
            position:'bottom'
        },
        legend: 'none',

        hAxis: {
            title: `${hAxisTitle}`,
            textStyle : {
                fontSize : 12,
            },
            titleTextStyle : {
                fontName : "Garamond",
                italic : false,
                fontSize : 16,
                bold: true
            },
        },
        vAxis: {
            title: `${vAxisTitle}`,
            textStyle : {
                fontSize : 12,
            },
            titleTextStyle : {
                fontName : "Garamond",
                italic : false,
                fontSize : 16,
                bold: true
            },
        },
        chartArea: {
            width: '70%',
            height: '70%',
            left:60,
        },
        height:300,
        backgroundColor: '#e8f2fa',
        pointSize: .1,
    };

}

export const chartContainerStyles=`w-full md:w-1/2 xl:w-full p-0 md:p-2 xl:p-0 inline-block rounded`;