import {getToken} from './tokenStore';


const getHeaders = () => {
    const headers = {
        'Authorization': getToken(),
        'Content-Type':'application/json',
    }

    return headers;
}


export default getHeaders;