import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as TooltipLib} from 'react-tooltip'

const Tooltip = (props) => {
    const {anchorId, content, position} = props;
    return (
        <TooltipLib
            anchorId={anchorId}
            content={content}
            place={position}
            positionStrategy={`fixed`}
        />
    )
}

export default Tooltip;